import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import React, { FC, PropsWithChildren } from 'react';
import { fullUrl } from '../utils/browser-utils';

const onRedirectCallback = (appState?: AppState) => {
  // console.log('## REDIRECT CALLBACK', { appState, user });
  navigate(appState?.returnTo || '/', { replace: true });
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  // const redirect_uri = `${getBaseUrl()}/login-callback`;
  const redirect_uri = fullUrl('');

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN!}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID!}
      authorizationParams={{ redirect_uri }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
