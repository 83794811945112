import React, { PropsWithChildren, createContext, useContext } from 'react';
import { getBrowserWixClient, WixSession } from '../wix-client';

export const WixSessionContext = createContext<WixSession | null>(null);

export const useWixSession = () => useContext(WixSessionContext)!;

export const WixSessionProvider = ({ children }: PropsWithChildren) => (
  <WixSessionContext.Provider value={{ wixClient: getBrowserWixClient() }}>
    {children}
  </WixSessionContext.Provider>
);
