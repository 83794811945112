import qs from 'qs';

export const isBrowser = () => {
  return (
    typeof window !== 'undefined' &&
    !!window.document &&
    !!window.document.createElement
  );
};

export const isWindowDefined = () => {
  return typeof window !== 'undefined';
};

export const openInNewTab = (url: string) => {
  if (!isWindowDefined()) return;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const getBaseUrl = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : process.env.VERCEL_ENV === 'preview'
    ? `https://${process.env.VERCEL_URL}`
    : 'https://www.vertical.lt';

export const fullUrl = (relativeUrl: string) => getBaseUrl() + relativeUrl;

export const getQueryParams = () => {
  const query = isWindowDefined() ? window.location.search : '';
  const params = qs.parse(query, { ignoreQueryPrefix: true });
  return params;
};

export const reloadPage = () => {
  isWindowDefined() && window.location.reload();
};
