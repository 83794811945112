import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { AppProviders } from './src/context/app-providers';

import './src/styles/global.scss';
import './src/styles/tailwind.css';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  // @ts-ignore
  <AppProviders>{element}</AppProviders>
);
