import React, { PropsWithChildren } from 'react';
import { AuthProvider } from './auth-provider';
import { RollbarProvider } from './rollbar-provider';
import { WixSessionProvider } from './wix-session-provider';

export const AppProviders: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <AuthProvider>
      <RollbarProvider>
        <WixSessionProvider>{children}</WixSessionProvider>
      </RollbarProvider>
    </AuthProvider>
  );
};
