import React, { PropsWithChildren } from 'react';
import { Provider } from '@rollbar/react';
import { Configuration } from 'rollbar';

const IGNORED_ERRORS = [
  /Minified React error #418/,
  /Minified React error #423/,
  /Minified React error #425/,
];

export const RollbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const rollbarConfig: Configuration = {
    accessToken:
      process.env.ROLLBAR_ACCESS_TOKEN ||
      process.env.GATSBY_ROLLBAR_ACCESS_TOKEN,
    enabled: !!process.env.NODE_ENV && process.env.NODE_ENV !== 'development',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment:
        process.env.GATSBY_VERCEL_ENV ||
        process.env.VERCEL_ENV ||
        'development',
    },
    autoInstrument: {
      network: true,
      log: true,
      dom: true,
      navigation: true,
      connectivity: true,
      contentSecurityPolicy: true,
      errorOnContentSecurityPolicy: false,
    },
    checkIgnore: (isUncaught, args, payload) => {
      // @ts-ignore
      const message = payload.body?.trace?.exception?.message;
      if (!message) {
        return false;
      }
      return IGNORED_ERRORS.some((pattern) => message.match(pattern));
    },
  };

  return <Provider config={rollbarConfig}>{children}</Provider>;
};
