import { createClient, OAuthStrategy } from '@wix/sdk';
import {
  availabilityCalendar,
  services,
  extendedBookings,
  bookings,
} from '@wix/bookings';
import * as data from '@wix/data';
// import { WIX_REFRESH_TOKEN } from '@app/model/auth/auth.const';
// import { PHASE_PRODUCTION_BUILD } from 'next/constants';

const WIX_REFRESH_TOKEN = 'wrefreshToken';

export type CookieStore = {
  get(name: string): string | undefined;
};
const getRefreshToken = (cookieStore: CookieStore) =>
  // process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD
  process.env.NEXT_PHASE !== 'phase-production-build'
    ? JSON.parse(cookieStore.get(WIX_REFRESH_TOKEN) || '{}')
    : {};

export const getWixClient = ({ cookieStore }: { cookieStore: CookieStore }) =>
  createClient({
    modules: {
      bookingsAvailability: availabilityCalendar,
      bookings: extendedBookings,
      bookingsActions: bookings,
      services,
      data,
    },
    auth: OAuthStrategy({
      clientId: process.env.GATSBY_WIX_CLIENT_ID!,
      tokens: {
        refreshToken: getRefreshToken(cookieStore),
        accessToken: { value: '', expiresAt: 0 },
      },
    }),
  });

export type WixClient = ReturnType<typeof getWixClient>;
